import React from "react"
import { createPortal } from "react-dom"
import { Modal as _Modal } from "@elevate_security/elevate-component-library"
import { ModalWrapper } from "./Modal.styles"

// Wrapper around component library modal to give it more sensible defaults,
// without having to make breaking changes in component library. Prefer to use
// this for all new modals instead of the one in component library.
export function Modal({ className, children, ...props }: any) {
  return createPortal(
    <ModalWrapper className={className}>
      <_Modal {...props}>{children}</_Modal>
    </ModalWrapper>,
    document.body,
  )
}
